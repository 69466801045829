import React from 'react'
import { FullWidthCard, PictureCardWithInteriorBorder, PictureCards } from '../cards'
import { CardHeader } from '../../typography'
import data from './our-beliefs-data'
import * as styles from './our-beliefs.module.css'

const OurBeliefs = () => (
  <FullWidthCard>
    <CardHeader>Our Beliefs</CardHeader>
    <PictureCards>
      {data.map((tileData) => (
        <PictureCardWithInteriorBorder
          key={tileData.imgSrc}
          img={
            <img
              src={tileData.imgSrc}
              alt={tileData.alt}
              className={styles.tileImg}
            />
          }
          color={tileData.color}
        >
          <h3 className={styles.tileHeader}>{tileData.title}</h3>
          <p>{tileData.explanation}</p>
        </PictureCardWithInteriorBorder>
      ))}
    </PictureCards>
  </FullWidthCard>
)

export default OurBeliefs

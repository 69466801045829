import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavigationButton from "./navigation-buttons";
import * as styles from "./bio-cards.module.css";

export default function BioCards({ staff, startIndex }) {
  const [index, setIndex] = React.useState(startIndex);

  React.useEffect(() => {
    setIndex(startIndex);
  }, [startIndex, setIndex]);

  const nextName = () => {
    return staff[(index + 1) % staff.length].name;
  };

  const previousName = () => {
    return index === 0 ? staff[staff.length - 1].name : staff[index - 1].name;
  };


  const { image, name, email, title, childContentfulPersonShortBioTextNode } = staff[index];


  const staffImage = getImage(image)
  const bioHtml = childContentfulPersonShortBioTextNode.childMarkdownRemark.html
  console.log(bioHtml)

  return (
    <>
      <div className={styles.bioCard}>
        <div className={styles.bioImage}>
          <GatsbyImage image={staffImage} alt={name} />
        </div>
        <div className={styles.bioInfo}>
          <h2 className={styles.name}>{name}</h2>
          <p className={styles.title}>{title}</p>
          <div
            className={styles.bio}
            dangerouslySetInnerHTML={{ __html: bioHtml }}
          ></div>
          {/* <a className={styles.link} href="#">
            Why I joined Dream Sports Africa
          </a> */}
        </div>
      </div>

      <div className={styles.buttonPanel}>
        <NavigationButton
          label={previousName()}
          direction="previous"
          onClick={() => setIndex(index === 0 ? staff.length - 1 : index - 1)}
        />
        <NavigationButton
          label={nextName()}
          direction="next"
          onClick={() => setIndex(index === staff.length - 1 ? 0 : index + 1)}
        />
      </div>
    </>
  );
}

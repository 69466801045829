import React from 'react'
import { graphql } from 'gatsby'
import {StaticImage} from 'gatsby-plugin-image'
import { StandardLayout } from '../layout/layout'
import Signup from '../components/signup'
import OurMotivation from '../components/who-we-are/our-motivation'
import OurFocus from '../components/who-we-are/our-focus'
import OurBeliefs from '../components/who-we-are/our-beliefs'
import DreamTeam from '../components/who-we-are/dream-team'
import * as colors from '../colors.module.css'

export default function WhoWeAre({ data, location }) {
  const staffData = data
  return (
    <StandardLayout
      title="Who We Are"
      subtitle="A diverse and innovative team, founded by Africans, who share a dream of a continent with leaders who transform themselves and their communities"
      backgroundColor={colors.redBackground}
      titleColor={colors.navy}
      img={<StaticImage src="../images/hands_clasped.png" imgStyle={{ objectPosition: '5% 5%' }} loading="eager" alt="hands clasped"/>}
      ogImageSrc="../images/hands_clasped.png"
    >
      <OurBeliefs />
      <OurMotivation />
      <OurFocus />
      <DreamTeam location={location} staffData={staffData}/>
      <Signup />
    </StandardLayout>
  )
}

export const pageQuery = graphql`
{
  allContentfulPerson(sort: {order: ASC, fields: order}, filter: {name: {ne: null}}) {
    nodes {
      id
      name
      email
      order
      title
      team {
        teamName
      }
      image {
        gatsbyImageData
        fixed(resizingBehavior: SCALE) {
          src
        }
      }
      childContentfulPersonShortBioTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
  allContentfulTeam(
    sort: {order: ASC, fields: order}
    filter: {teamName: {ne: null}}
  ) {
    nodes {
      id
      teamName
      order
    }
  }
}
`

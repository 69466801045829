import React from "react";
import * as styles from "./navigation-buttons.module.css";

const PreviousIcon = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="21.5" stroke="white" strokeWidth="2" />
    <path
      d="M14.7857 22.4998L25.0714 32.7855"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M25.0714 12.2141L14.7857 22.4999"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const NextIcon = () => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22.5" cy="22.5" r="21.5" stroke="white" strokeWidth="2" />
    <path
      d="M18.6429 12.2141L28.9286 22.4999"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M28.9285 22.5L18.6428 32.7857"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export default function NavigationButton({ label, direction, onClick }) {
  return (
    <button type="button" onClick={onClick} className={styles.navigationButton}>
      <div>
        {direction === "next" ? <NextIcon /> : <PreviousIcon />}
        <p className={styles.navLabel}>{label}</p>
      </div>
    </button>
  );
}

import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { LeftRightImageCards } from '../cards'
import * as styles from './our-motivation.module.css'

const OurMotivation = () => {
  return (
    <LeftRightImageCards
      title="Our Motivation"
      subtitle="Opportunities, participation, and employment remain low for an African continent whose median age is 19.7 years. Engaging, educating, and empowering African youth has the power to benefit society at every level."
      img={<StaticImage src="../../images/lunges.png" imgStyle={{ objectPosition: '50% 10%' }} alt="Kids doing lunges" />}
      imageFirst
      className={styles.ourMotivation}
    />
  )
}

export default OurMotivation

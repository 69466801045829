import * as styles from './our-beliefs.module.css'

const data = [
  {
    imgSrc: '/all_hands_raised.png',
    alt: 'Girl and boy smiling while flexing',
    color: '#ef4b40',
    title: 'Exercise is for everyone',
    explanation:
      'All genders, ages, and cultures enjoy and benefit from physical activity. We make exercise accessible with simple, zero-equipment workouts that all can participate in and take home with them.'
  },
  {
    imgSrc: '/triangle_lunge.png',
    alt: 'Male and female in cresent lunge pose by lake',
    color: '#282862',
    title: 'Movement develops mindfulness',
    explanation:
      'The body and mind are parts of a unified whole. When we learn to improve our fitness we find we can exert more control in other aspects of our lives too.'
  },
  {
    imgSrc: '/our-beliefs-jumping.png',
    alt: 'Workout group jumping in unison',
    color: '#F8BE20',
    title: 'Fitness is fun!',
    explanation:
      "We grow most when we're naturally engaged. Playing with others and improving our mastery over time are inherently enjoyable."
  }
]

export default data

import React from "react";
import * as styles from "./modal.module.css";

const CloseButton = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="16" stroke="white" strokeWidth="2" />
    <path
      d="M11.5989 11.252L22.8837 22.5368"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M12 22L23 11"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export default function Modal({ show, onClose, children }) {
  const showHideClass = show ? styles.show : styles.noDisplay;

  return (
    <div className={`${styles.modalBackdrop} ${showHideClass}`}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.modalCloseContainer}>
            <button type="button" onClick={onClose}>
              <CloseButton />
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}

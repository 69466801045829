import React from 'react'
import { FullWidthVerticallyCenteredCard } from '../cards'
import { CardHeader, MediumParagraph } from '../../typography'
import * as colors from '../../colors.module.css'


export default function OurFocus() {
  return (
    <FullWidthVerticallyCenteredCard className={colors.navyBackground}>
      <CardHeader className={colors.red}>Our Focus</CardHeader>
      <MediumParagraph className={colors.white}>At Dream Sports Africa we have a deep commitment to building the capacity of youth to help them reach their full potential. As athletes ourselves, we all believe in the power of sports and exercise in building valuable life skills. Through our programs, youth are able to gain confidence, work as a team, build healthy habits, and have fun!</MediumParagraph>
    </FullWidthVerticallyCenteredCard>
  )
}

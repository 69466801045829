import React from "react"
import { findIndex } from 'lodash'
import { FullWidthCard } from "../cards"
import Modal from "./modal"
import BioCards from "./bio-cards"
import { CardHeader } from '../../typography'
import * as colors from '../../colors.module.css'
import * as styles from "./dream-team.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const StaffCard = ({ index, member, onClick, ...rest }) => {
  const { name, title } = member
  const staffImage = getImage(member.image)
  return (
    <div className={styles.staffCard} onClick={() => onClick(index)} {...rest}>
      <GatsbyImage className={styles.staffImage} image={staffImage} alt={name} />
      <div className={styles.staffName}>{name}</div>
      <div className={styles.staffTitle}>{title}</div>
    </div>
  )
}

export default function DreamTeam({ location, staffData }) {
  const [showStaffBioCardAtIndex, setShowStaffBioCardAtIndex] = React.useState(null)
  const staffDataCollection = staffData.allContentfulPerson.nodes
  const teamsCollection = staffData.allContentfulTeam.nodes

  React.useEffect(() => {
    if (location.hash && location.hash !== '#') {
      const hash = location.hash.slice(1)
      const matchingStaffIndex = findIndex(staffDataCollection, member => {
        const asHyphenated = member.name.toLowerCase().split(/\s/).map(word => word.replace(/\W/g, '')).join('-')
        return hash === asHyphenated
      })

      if (matchingStaffIndex !== -1) {
        setShowStaffBioCardAtIndex(matchingStaffIndex)
      }
    }
  }, [location.hash])

  return (
    <>
      <Modal show={showStaffBioCardAtIndex != null} onClose={() => setShowStaffBioCardAtIndex(null)}>
        <BioCards staff={staffData.allContentfulPerson.nodes} startIndex={showStaffBioCardAtIndex || 0} />
      </Modal>

      <FullWidthCard title="The Dream Team">
        <CardHeader className={colors.navy}>Our Dream Team</CardHeader>
        <div className={styles.description}>
          Our diverse team of innovative Africans and Americans brings over 40
          years of combined youth development through sports programming
          experience.
        </div>

        {teamsCollection.map((currentTeam, index) => {
          // Don't show teams without any members
          if (staffDataCollection.every(member => member.team.teamName !== currentTeam.teamName)) return null;
          return (
            <div key={currentTeam.teamName}>
              <h4>{currentTeam.teamName}</h4>
              <div className={styles.staffContainer}>
                {staffDataCollection.map((member, index) => {
                  const memberTeamName = member.team.teamName
                  return memberTeamName === currentTeam.teamName ? (
                    <StaffCard
                      title={`Show ${member.name}'s bio`}
                      key={member.id}
                      index={index}
                      member={member}
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowStaffBioCardAtIndex(index)}
                    />
                  ) : null
                })}
              </div>
            </div>
          )
        })}
      </FullWidthCard>
    </>
  )
}
